<template>
  <div class="app-container v">
    <div class="head-container">
      <el-date-picker size="small" class="filter-item" v-model="query.dateRange" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="调拨日期" end-placeholder :default-time="defaultTime" @change="toQuery" />
      <quick-select class="filter-item" v-model="query.outWarehouseId" clearable displayField="name" url="api/warehouse" filterable placeholder="发货库房" style="width:180px" @change="toQuery" />
      <quick-select class="filter-item" v-model="query.inWarehouseId" clearable displayField="name" url="api/warehouse" filterable placeholder="收货库房" style="width:180px" @change="toQuery" />
      
      <el-input v-model.trim="query.formCode" clearable placeholder="输入调拨单号进行搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <el-input v-model.trim="query.blurry" clearable placeholder="输入商品名称/编码等进行搜索" class="filter-item" style="width: 240px" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" height="200px">
      <el-table-column prop="goodsCode" label="商品编码" width="130" />
      <el-table-column prop="erpCode" label="ERP编码" width="130" />
      <el-table-column prop="goodsName" label="商品名称" min-width="120" />
      <el-table-column prop="goodsSpec" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
      <el-table-column prop="goodsBrandName" label="品牌" min-width="80" />
      <!-- <el-table-column prop="goodsCategoryName" label="分类" min-width="80" /> -->
      <el-table-column prop="goodsSeriesName" label="系列" min-width="80" />
      <el-table-column prop="formCode" label="调拨单号" min-width="125" />
      <el-table-column prop="outWarehouseName" label="发货库房" min-width="120" />
      <el-table-column prop="inWarehouseName" label="收货库房" min-width="120" />
      <el-table-column label="调拨日期" width="100" :formatter="r => {return new Date(r.createAt).format('yyyy/MM/dd');}" />
      <el-table-column prop="formInfo" label="单据说明" min-width="120" show-overflow-tooltip />
      <el-table-column prop="info" label="说明" min-width="120" show-overflow-tooltip />
      <el-table-column label="审批日期" width="100" :formatter="r => {return r.checkTime ? new Date(r.checkTime).format('yyyy/MM/dd') : '';}" />
      <el-table-column prop="checkInfo" label="审批意见" min-width="120" show-overflow-tooltip />
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      downloadLoading:false,
      defaultTime: ["00:00:00", "23:59:59"],
      query: {
        dateRange: null,
        outWarehouseId: null,
        inWarehouseId: null,
        formCode: null,
        blurry: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/allocationForm/item";
      this.params = {
        page: this.page,
        size: this.size,
        outWarehouseId: this.query.outWarehouseId,
        inWarehouseId: this.query.inWarehouseId,
        formCode: this.query.formCode,
        blurry: this.query.blurry,
        sort: "formCode,desc",
      };
      if (this.query.dateRange && this.query.dateRange.length === 2) {
        this.params["beginTime"] = this.query.dateRange[0];
        this.params["endTime"] = this.query.dateRange[1];
      }
      return true;
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/allocationForm/item/download", this.params)
        .then((result) => {
          downloadFile(result, "调拨明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    }
  },
};
</script>